/* Accordion -- creates elements that expand/collapse on click */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var OPEN_CLASS = 'open';
  var BASE_CLASS = 'accordion';
  var SPEED = 'fast';

  function Accordion(headerClass, bodyClass, onOpen) {
    this.headerClass = headerClass;
    this.bodyClass = bodyClass;
    this.onOpen = onOpen;

    var bodySelector = '.' + bodyClass;
    $(bodySelector).hide();
    $('.' + headerClass)
      .addClass(BASE_CLASS)
      .click(function () {
        var header = $(this);
        if (header.hasClass(OPEN_CLASS)) {
          header.next(bodySelector).slideUp(SPEED);
          header.removeClass(OPEN_CLASS);
        }
        else {
          header.next(bodySelector).slideDown(SPEED);
          header.addClass(OPEN_CLASS);
          if (onOpen) {
            onOpen(header);
          }
        }
      });
  }

  Accordion.prototype.openIndex = function (index) {
    var toOpen = $('.' + this.headerClass).eq(index);
    if (toOpen.hasClass(OPEN_CLASS)) {
      this.onOpen(toOpen);
    }
    else {
      toOpen.click();
    }
  };

  Accordion.prototype.expandAll = function () {
    $('.' + this.headerClass).each(function (index) {
      var element = $(this);
      if (!element.hasClass(OPEN_CLASS)) {
        element.click();
      }
    })
  };

  Accordion.prototype.collapseAll = function (openFirst) {
    $('.' + this.headerClass).each(function (index) {
      var element = $(this);
      if (element.hasClass(OPEN_CLASS)) {
        element.click();
      }
    })
  };

  return Accordion;
}));