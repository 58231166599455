/* Util -- collection of useful methods */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var module = {};

  module.MOMENT_DATE_FORMATS = {
    MONTH_DAY: "MMM D",
    MONTH_DAY_YEAR: "MMM D YYYY",
    HOUR_ZERO_MINUTE: "H:00",
    TIME_ZONE: "z"
  };

  module.makeAbsolute = function (relative_url) {
    if (/^http(s)?:/.test(relative_url)) {
      return relative_url;
    }
    var base_url = window.location.href;
    var start = base_url.indexOf("//");
    var end = base_url.indexOf("/", start + 2);
    if (end === -1) {
      end = base_url.length;
    }
    base_url = base_url.substring(0, end);
    if (relative_url.substring(0, 1) !== "/") {
      base_url += "/";
    }
    return base_url + relative_url;
  };

  module.togglePassword = function (fieldId, buttonId) {
    var field = $('#' + fieldId);
    var label = null;
    var type = null;
    if (field.attr('type') === 'text') {
      type = 'password';
      label = I18n.t("globals.show");
    }
    else {
      type = 'text';
      label = I18n.t("globals.hide");
    }
    field.attr('type', type);
    if (buttonId != null) {
      $('#' + buttonId).text(label);
    }
  };

  module.pad = function (number) {
    return (number < 10 ? "0" : "") + number;
  };

  function secondsToMilliseconds(seconds) {
    return seconds * 1000;
  }

  module.millisecondsToSeconds = function (milliseconds) {
    return milliseconds / 1000;
  };

  function secondsToDate(seconds) {
    return new Date(secondsToMilliseconds(seconds));
  }

  module.formatDate = function (seconds) {
    var date = secondsToDate(seconds);
    return date.getUTCFullYear() + "-" + module.pad(date.getUTCMonth() + 1) + "-" + module.pad(date.getUTCDate());
  };

  module.createElement = function (elementTag) {
    return $(document.createElement(elementTag));
  };

  module.getElementById = function (elementId) {
    return $('#' + elementId);
  };

  module.formatMomentToIsoShort = function (momentToFormat) {
    if (!momentToFormat.isValid()) {
      return null;
    }
    return momentToFormat.format('YYYY-MM-DD HH:mm:ss');
  };

  module.formatMomentWithConfiguredTimezone = function (momentToFormat, formatString) {
    formatString = formatString || "YYYY-MM-DD HH:mm:ss z";
    return moment(momentToFormat).tz(configuredTimezone).format(formatString);
  };

  module.dateInConfiguredTimezoneToMomentInUtc = function (date) {
    return moment.tz(date, configuredTimezone).utc();
  };

  module.formatDateWithConfiguredTimezone = function (date, formatString) {
    return Util.formatMomentWithConfiguredTimezone(moment.utc(date), formatString)
  };

  module.getConfiguredTimezoneOffset = function () {
    // momentjs library replaced offset with utcOffset without changing the sign of the value returned.
    // This code is in place to make sure we catch the change when they update utcOffset.
    // See https://github.com/moment/moment-timezone/issues/397
    var offset = moment.tz.zone(configuredTimezone).utcOffset(moment().valueOf());
    if (moment.tz.zone("America/New_York").utcOffset(moment().valueOf()) < 0) {
      console.error("utcOffset for momentJS has changed sign");
      return offset * -1;
    }
    return offset;
  };

  var ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
  // d3 time scales require Date objects. Dates can only be in UTC or the browser timezone.
  // This converts the UTC date to the configured timezone and then "spoofs" them as UTC so
  // that they are valid Date objects.
  module.convertToTimezoneAndFakeUTC = function (timestamp) {
    var localMoment = moment.unix(timestamp).tz(configuredTimezone);
    var fakeUTCDate = new Date(localMoment.format(ISO_FORMAT) + "Z");
    return fakeUTCDate;
  };

  module.convertFakeUTCToMoment = function (date) {
    var localTimeStringInFakeUTC = date.toISOString();
    var localTimeString = localTimeStringInFakeUTC.slice(0, -1);
    return moment.tz(localTimeString, configuredTimezone);
  };

  function blankOrNaNString(value) {
    return value ? (value.trim().length === 0 || isNaN(value)) : true;
  }

  module.elementBlankOrNaNString = function (element) {
    return blankOrNaNString(element.val());
  };

  module.isPowerOf10 = function (value) {
    //not very efficient but Math.log(value) / Math.LN10 has rounding errors
    while (value >= 10) {
      value /= 10;
    }
    return value === 1;
  };

  var ONE_MILE_IN_METRES = 1609.344;
  var ONE_FOOT_IN_METRES = 0.3048;
  var ONE_KILOMETRE_IN_METRES = 1000;
  var ONE_MILE_IN_KILOMETRES = ONE_MILE_IN_METRES / ONE_KILOMETRE_IN_METRES;

  module.convertMetresToFeet = function (metres) {
    return metres / ONE_FOOT_IN_METRES;
  };

  module.convertMetresToMiles = function (metres) {
    return metres / ONE_MILE_IN_METRES;
  };

  module.convertMetresToKilometres = function (metres) {
    return metres / ONE_KILOMETRE_IN_METRES;
  };

  module.convertKilometresToMiles = function (kilometres) {
    return kilometres / ONE_MILE_IN_KILOMETRES;
  };

  module.convertDistanceToConfiguredUnits = function (distance) {
    if (distance === '') {
      return null;
    }
    return window.useImperialUnits ? distance * ONE_MILE_IN_KILOMETRES : distance;
  };

  module.showAbout = function () {
    $("#about-dialog").dialog({
      modal: true,
      buttons: [{
        text: I18n.t("globals.ok"),
        click: function () {
          $(this).dialog("close");
        }
      }],
      closeText: I18n.t("globals.close")
    })
  };

  module.showAngularPopup = function (url, divId) {
    var dashboardPopup = Util.getElementById(divId);
    dashboardPopup.html("<iframe src=\"" + url + "\" style='width:100%;height:100%;border:none'></iframe>");
    var divHeight = $(window).height() * 0.8;
    dashboardPopup.dialog({
      width: divHeight * 1.5,
      height: divHeight,
      buttons: [],
      closeText: I18n.t("globals.close"),
      resizable: true,
      modal: true,
      closeOnEscape: true,
    });
  };

  module.updateHrefSearchFromBrowser = function (linkObject) {
    let hrefLink = $(linkObject).attr('href');
    let searchQueryFromBrowser = window.location.search;
    $(linkObject).attr('href', hrefLink.split('?')[0] + searchQueryFromBrowser);
  };

  module.reloadWithNewLocale = function (newLocaleCode) {
    window.location.pathname = window.location.pathname.replace(I18n.locale, newLocaleCode);
  };

  $(document).ready(function () {
    $('#filter_input').keyup(function (event) {
      if (event.keyCode == '27') {
        $(this).val("");
      }
      var regex = new RegExp($(this).val(), "i");
      $(".filterable tr:hidden").show();
      $(".filterable tr:has(td)").each(function () {
        if (!$(this).text().match(regex)) {
          $(this).hide();
        }
      });
      if ($(".filterable tr:hidden").length > 0) {
        $('#some_things').show();
        $('#all_things').hide();
        $('#num_things').html("" + ($(".filterable tr:visible").length - 1));
      }
      else {
        $('#some_things').hide();
        $('#all_things').show();
      }
    });
  });

  return module;
}));
