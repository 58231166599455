/* NmxChartBuilder */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var CLASSES = {
    CHART: 'nmx_chart',
    AXIS: {
      ALL: 'axis',
      X: 'x',
      Y: 'y'
    },
    NO_DATA: 'no_data',
    ERROR: 'error_message',
    LINE: 'line',
    LABEL: 'line_label'
  };
  var TRANSITION_DURATION = 500;
  var LABEL_PADDING = 3;
  var X_TICK_SPACING = 200;

  var builder = {};
  var options = {};

  function Chart(id) {
    var self = this;
    self.id = id;
    self.plotContainer = d3.select(self.id);
    self.plotContainer.selectAll('svg').remove();
    self.plotSVG = self.plotContainer.append('svg').attr('class', CLASSES.CHART);

    self.xScale = options.xScale;
    self.yScale = d3.scale.linear();

    self.xAxis = d3.svg.axis().scale(self.xScale).orient("bottom").outerTickSize(0);
    self.yAxis = d3.svg.axis().scale(self.yScale).orient("left").outerTickSize(0);
    self.xAxis.tickFormat(options.xTickFormat);
    self.showMinXTick = options.showMinXTick;

    self.line = d3.svg.line()
      .x(function(d) {
        return self.xScale(d.x);
      })
      .y(function(d) {
        return self.yScale(d.y);
      });

    self.lineLabelPosition = function(d) {
      return translate((self.xScale(d.x) + LABEL_PADDING), self.yScale(d.y));
    }
  }

  Chart.prototype.draw = function(data) {
    var self = this;
    self.data = data;

    var sizing = getSizing(self.plotContainer, options.margin);

    self.plotSVG
      .attr("width", sizing.outerWidth)
      .attr("height", sizing.outerHeight);

    if (!self.data) {
      drawNoData(self, sizing);
    }
    else {
      drawWithData(self, sizing);
    }
  };

  Chart.prototype.resize = function() {
    var self = this;
    var sizing = getSizing(self.plotContainer, options.margin);
    self.plotSVG
      .attr("width", sizing.outerWidth)
      .attr("height", sizing.outerHeight);

    if (!self.data) {
      self.plotSVG.select(selector(CLASSES.NO_DATA))
        .transition().duration(TRANSITION_DURATION)
        .attr('x', sizing.centerX)
        .attr('y', sizing.centerY);
    }
    else {
      self.xScale.range([0, sizing.width]);
      self.yScale.range([sizing.height, 0]);

      if (self.showMinXTick) {
        addTickAtXAxisMinimum(self, sizing);
      }

      self.plotSVG.selectAll(selector(CLASSES.AXIS.ALL)).filter(selector(CLASSES.AXIS.X))
        .attr("transform", translate(0, sizing.height))
        .transition().duration(TRANSITION_DURATION)
        .call(self.xAxis);

      self.plotSVG.selectAll(selector(CLASSES.AXIS.ALL)).filter(selector(CLASSES.AXIS.Y))
        .transition().duration(TRANSITION_DURATION)
        .call(self.yAxis);

      self.plotSVG.selectAll(selector(CLASSES.LINE))
        .transition().duration(TRANSITION_DURATION)
        .attr('d', self.line);

      self.plotSVG.selectAll(selector(CLASSES.LABEL))
        .transition().duration(TRANSITION_DURATION)
        .attr("transform", self.lineLabelPosition);
    }
  };

  Chart.prototype.displayNoDataErrorMessage = function(message) {
    this.plotSVG.select(selector(CLASSES.NO_DATA)).classed(CLASSES.ERROR, true).text(message);
  };

  function translate(x, y) {
    return "translate(" + x + "," + y + ")";
  }

  function addTickAtXAxisMinimum(chart, sizing) {
    var automaticTicks = chart.xScale.ticks(sizing.width / X_TICK_SPACING);
    var xAxisMinimumTick = chart.xScale.domain()[0];
    var tickInterval = automaticTicks.length > 1 ? automaticTicks[1] - automaticTicks[0] : chart.xScale.domain()[1] - chart.xScale.domain()[0];
    if ((automaticTicks[0] - xAxisMinimumTick) > (tickInterval / 2)) {
      chart.xAxis.tickValues([xAxisMinimumTick].concat(automaticTicks));
    }
    else {
      automaticTicks[0] = xAxisMinimumTick;
      chart.xAxis.tickValues(automaticTicks);
    }
  }

  function drawWithData(chart, sizing) {
    chart.xScale
      .range([0, sizing.width])
      .domain(d3.extent(chart.data, function(d) {
        return d.x
      }));

    if (chart.showMinXTick) {
      addTickAtXAxisMinimum(chart, sizing);
    }

    chart.yScale
      .range([sizing.height, 0])
      .domain(d3.extent(chart.data, function(d) {
        return d.y
      }));

    var innerG = chart.plotSVG.append("g")
      .attr("transform", translate(options.margin.left, options.margin.top));

    var xAxisG = innerG.append("g")
      .attr("class", CLASSES.AXIS.ALL + " " + CLASSES.AXIS.X)
      .attr("transform", translate(0, sizing.height))
      .call(chart.xAxis);

    var yAxisG = innerG.append("g")
      .attr("class", CLASSES.AXIS.ALL + " " + CLASSES.AXIS.Y)
      .call(chart.yAxis);

    innerG.append("path")
      .datum(chart.data)
      .attr("class", CLASSES.LINE)
      .attr("d", chart.line);

    drawVerticalLines(chart, innerG, options.verticalLinesData);
  }

  function drawVerticalLines(chart, lineWrapper, verticalLinesData) {
    verticalLinesData.forEach(function(verticalLine) {
      var lineData = [{
        x: verticalLine.x,
        y: chart.yScale.domain()[0]
      }, {
        x: verticalLine.x,
        y: chart.yScale.domain()[1]
      }];
      var labelData = [{
        x: verticalLine.x,
        y: chart.yScale.domain()[1]
      }];

      lineWrapper.append("path")
        .datum(lineData)
        .attr("class", CLASSES.LINE + " " + verticalLine.class)
        .attr("d", chart.line);

      lineWrapper.append("text")
        .data(labelData)
        .attr("transform", chart.lineLabelPosition)
        .attr("text-anchor", "start")
        .attr("dy", ".7em")
        .attr("class", CLASSES.LABEL + " " + verticalLine.class)
        .text(verticalLine.title);
    });
  }

  function drawNoData(chart, sizing) {
    chart.plotSVG.selectAll('g').remove();
    chart.plotSVG.append('g')
      .append('text')
      .attr('dy', '.35em')
      .style('text-anchor', 'middle')
      .attr('class', CLASSES.NO_DATA)
      .attr('x', sizing.centerX)
      .attr('y', sizing.centerY)
      .text(options.noData);
  }

  function selector(classToConvert) {
    return "." + classToConvert;
  }

  function getSizing(container, margin) {
    var outerWidth = container.node().getBoundingClientRect().width;
    var outerHeight = container.node().getBoundingClientRect().height;
    var width = outerWidth - margin.left - margin.right;
    var height = outerHeight - margin.top - margin.bottom;
    return {
      outerWidth: outerWidth,
      outerHeight: outerHeight,
      width: width,
      height: height,
      centerX: margin.left + width / 2,
      centerY: margin.top + height / 2
    }
  }

  builder.setOptions = function(chartOptions) {
    $.extend(options, chartOptions);
    return builder;
  };

  builder.setTimeScale = function(tickFormatter) {
    $.extend(options, {
      xScale: d3.time.scale.utc(),
      xTickFormat: tickFormatter,
      showMinXTick: true
    });
    return builder;
  };

  builder.addVerticalLines = function(data) {
    $.extend(options, {verticalLinesData: data});
    return builder;
  };

  builder.buildLineChart = function(id) {
    return new Chart(id);
  };

  return function() {
    options = {
      margin: { top: 30, right: 30, bottom: 30, left: 30 },
      xScale: d3.scale.linear(),
      xTickFormat: null,
      verticalLinesData: [],
      noData: "No data available"
    };
    return builder;
  }

}));